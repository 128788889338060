import { Component, Inject } from '@angular/core';
import {
    CookieService,
    IMenu,
    LocalStorageService,
    PERMISSOES_SERVICE_TOKEN,
    PermissoesService
} from '@sbt-suite/components';
import { environment } from 'src/environments/environment';
import { menuRoles } from './core/constants/roles';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    MODE = environment.MODE;
    URL_LOGIN = environment.URL_LOGIN;
    URL_LOGOUT = environment.URL_LOGOUT;

    menuItens: IMenu[] = [
        {
            label: 'Cadastro',
            roles: menuRoles.itensMenu.cadastro.roles,
            itens: [
                {
                    label: 'Participações',
                    icone: 'tv',
                    link: '/participacao',
                    roles: Object.values(menuRoles.itensMenu.cadastro.participacoes).flat()
                },
                {
                    label: 'Convidados',
                    icone: 'person',
                    link: '/convidado',
                    roles: Object.values(menuRoles.itensMenu.cadastro.convidados).flat()
                }
            ]
        },
        {
            label: 'Calendário',
            roles: menuRoles.itensMenu.calendario.roles,
            itens: [
                {
                    label: 'Datas Participações',
                    icone: 'calendar_month',
                    link: '/participantes',
                    roles: Object.values(menuRoles.itensMenu.calendario.participantes).flat()
                }
            ]
        },
        {
            label: 'Parametrização',
            roles: menuRoles.itensMenu.parametrizacoes.roles,
            itens: [
                {
                    label: 'Programa',
                    icone: 'tv',
                    link: '/programa',
                    roles: Object.values(menuRoles.itensMenu.parametrizacoes.programa).flat()
                }
            ]
        }
    ];

    constructor(
        @Inject(PERMISSOES_SERVICE_TOKEN) private _permissoesService: PermissoesService,
        private _cookie: CookieService,
        private _localStorage: LocalStorageService
    ) {
        _permissoesService.initPermissoes();
    }

    ngOnInit() {}

    async logout(event: boolean) {
        if (event) {
            try {
                this.clearCookie();
                this._localStorage.clear();
                window.open(this.URL_LOGOUT, '_self');
            } catch (error) {
                console.error(error);
            }
        }
    }

    private clearCookie() {
        let domain = '';
        if (this.MODE == 'dev' || this.MODE == 'prod') {
            domain = 'tvsbt.com.br';
        }
        this._cookie.delete(environment.ACCESS_TOKEN, '/', domain);
        this._cookie.delete(environment.REFRESH_TOKEN, '/', domain);
    }
}
